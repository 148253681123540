/************** wcu-thumb-wrap ***************/
.wcu-thumb-wrap {
	.img-2 {
		margin: 120px 30px 0 0px;
	}
    @include sm {
        .img-1 {
            margin-left: 60px;
            margin-right: 0;
        }
        .img-2 {
            margin: 30px 60px 0 0px;
        }
    }
}