blockquote,
.wp-block-quote {
    font-size: 18px;
    line-height: 1.55;
    padding: 60px;
    font-weight: 400;
    font-family: $title-font;     
    display: block;
    position: relative;
    background-color: $smoke-color;
    overflow: hidden;
    margin: 40px 0;
    color: $title-color;
    font-style: italic;
    border-radius: 5px;
    border: 0;
    border-top: 3px solid $theme-color;
    p {
        font-size: 18px;
        font-weight: 400;
        font-family: $title-font;        
        font-style: normal;
        margin-top: -0.3em;
        margin-bottom: 23px;
        line-height: 1.55;
        color: $title-color;
        width: 100%;
        position: relative;
        z-index: 3;
        a {
            color: inherit;
        }
        cite {
            margin-top: 20px;
        }
    }
    &:before {
        content: "\f10e";
        font-family: $icon-font;
        position: absolute;
        right: 45px;
        bottom: 20px;
        font-size: 60px;
        font-weight: 700;
        opacity: 1;
        color: $theme-color;
        font-style: normal;
        background: transparent;
        text-align: center;
    }

    cite {
        display: block;
        font-size: 26px;
        position: relative;
        border-color: inherit;
        line-height: 1;
        font-weight: 900;
        margin-top: 0;
        margin-bottom: -0.2em;
        font-style: normal;
        color: $title-color;
        font-family: $title-font;
        &:before {
            content: '';
            position: relative;
            display: inline-block;
            height: 2px;
            width: 25px;
            background: $theme-color;
            margin-right: 15px;
            top: -7px;

        }
    }
    .desig {
        font-size: 12px;
        font-weight: 500;
        font-family: $title-font;
        font-style: initial;
        text-transform: uppercase;
        color: $body-color;
        margin-top: 8px;
        margin-bottom: -0.4em;
        display: block;
    }
    .quote-icon {
        position: absolute;
        right: 50px;
        bottom: 50px;
        width: 150px;
    }
    &.style-left-icon,
    &.is-large,
    &.is-style-large,
    &.has-text-align-right {
        padding: 170px 50px 50px;
    }
    &.style-left-icon {
        font-size: 18px;
        color: $body-color;
        font-weight: 400;
        line-height: 1.556;
        background-color: $smoke-color;
        padding-left: 160px;
        &:before {
            right: unset;
            left: 56px;
            top: 60px;
            font-size: 6rem;
            font-weight: 400;
            line-height: 4rem;
            color: $theme-color;
            text-shadow: none;
        }
        cite {
            color: $title-color;
            &:before {
                background-color: $title-color;
                top: 8px;
            }
        }
    }
    &.is-large,
    &.is-style-large {
        cite {
            &:before {
                top: unset;
                bottom: 13px;
            }
        }
    }
    &.has-text-align-right {
        border: 0;
        &:before {
            right: 50px;
            left: unset;
        }
    }
}
.wp-block-pullquote {
    padding: 0;
}
.wp-block-pullquote.is-style-solid-color blockquote p {
    margin-bottom: 20px;
}
.wp-block-pullquote blockquote,
.wp-block-pullquote.is-style-solid-color blockquote {
    &:before {
        left: 50%;
        transform: translate(-50%, 0px);
    }
}
.wp-block-column {
    blockquote,
    .wp-block-quote {
        &:before {
            width: 50px;
            height: 50px;
            line-height: 50px;
            top: 30px;
            left: 30px;
            font-size: 30px;
        }
        padding: 100px 15px 30px 15px;
        &.style-left-icon,
        &.is-large:not(.is-style-plain),
        &.is-style-large:not(.is-style-plain),
        &.has-text-align-right {
            padding: 100px 15px 30px 15px;
        }
    }
}
.blog-meta {
    display: block;
    margin-top: -0.4em;
    span,
    a {
        display: inline-block;     
        font-size: 14px;
        font-weight: 700;
        color: $title-color;
        position: relative;
        margin-right: 11px;
        text-transform: uppercase;
        i {
            margin-right: 9px;
            color: $theme-color;
        }
        &:last-child {
            margin-right: 0 !important;
            padding-right: 0;
        }
    }
    span {
        a {
            margin-right: 0 !important;
        }
    }
    a:hover {
        color: $theme-color;
    }
}

.blog-audio,
.blog-img,
.blog-video {
    img {
        transition: 0.4s ease-in-out;
        border-radius: 30px 30px 0 0;
    }
}

.blog-title {
    a {
        color: inherit;

        &:hover {
            color: $theme-color;
        }
    }
}
.blog-inner-title {
    margin-top: -0.25em;
    margin-bottom: 20px;
    font-weight: 900;
    i {
        color: $theme-color;
        margin-right: 4px;
    }
}

.blog-single-card {
    position: relative;
    margin-bottom: 60px;
    .blog-thumb {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
            border-radius: 5px;
        }
    }
    .blog-title {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 1;
        font-weight: 900;
    }
    .blog-text {
        margin-bottom: 40px;
        font-size: 18px;
        font-family: $title-font;
        font-weight: 400;
    }
    .blog-date {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(100%, -50%);
        a {
            background: $theme-color;
            height: 80px;
            width: 80px;
            border-radius: 5px 0 0 5px;
            text-align: center;
            display: inline-block;
            color: $white-color;
            font-family: $title-font;
            font-size: 18px;
            font-weight: 400;
            padding: 13px 10px 17px;
            span {
                display: block;
                font-size: 36px;
                font-weight: 700;
                margin-bottom: 7px;
            }
        }
    }
    .blog-meta {
        margin-bottom: 19px;
    }
    .social-links {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
        li {
            display: inline-block;
            margin-right: 3px;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            display: inline-block;
            @include equal-size(40px);
            line-height: 40px;
            background-color: $smoke-color;
            font-size: 14px;
            color: $body-color;
            text-align: center;
            &:hover {
                color: $white-color;
                background-color: $theme-color;
            }
        }
    }
    .blog-content {
        margin: -25px 80px 0 30px;
        position: relative;
        border-radius: 5px 0 5px 5px;
        background: $white-color;
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
        padding: 40px;
    }
    .blog-audio {
        line-height: 1;
    }
    .blog-audio,
    .blog-img,
    .blog-video {
        position: relative;
        overflow: hidden;
        background-color: $smoke-color;
    }
    .blog-img {
        .slick-arrow {
            --pos-x: 30px;
            --icon-size: 45px;
            border: none;
            background-color: $white-color;
            color: $theme-color;
            border-radius: 5px;
            &:hover {
                background-color: $theme-color;
                color: $white-color;
            }
        }
        .play-btn {
            --icon-size: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
            i {
                --icon-size: 100px;
                background: rgba(255, 255, 255, 0.102);
                font-size: 24px;
                width: var(--icon-size, 120px);
                height: var(--icon-size, 120px);
                line-height: var(--icon-size, 120px);
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    border:solid 2px #fff;
                    border-radius: 50%;
                    animation: rotate 5.0s infinite linear;
                }
                  
            }
            &:before,
            &:after {
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.18);
                  
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 30px 30px 0 0;
            background-color: rgba(16, 55, 65, 0.149);
            width: 100%;
            height: 100%;
              
        }
    }
    .read-more-btn,
    .line-btn {
        display: block;
        max-width: fit-content;
        margin-bottom: -1px;
    }
    .read-more-btn {
        margin-bottom: -8px;
        color: $theme-color;
    }
    .blog-post-wrap {
        background: $smoke-color3;
        border-radius: 6px;
        padding: 60px;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
            background: $theme-color;
            h5 {
                color: $white-color;
            }
        }
        h5 {
            margin-bottom: 0;
            transition: 0.4s;
        }
    }
    &:hover {
        .blog-img {
            .slick-arrow {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @include ml {
        .blog-title {
            font-size: 28px;
            line-height: 1.1;
        }
    }
    @include lg {
        .blog-date {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(0, -100%);
            a {
                border-radius: 5px 0 0 0px;
            }
        }
        .blog-content {
            margin: -25px 0px 0 30px;
        }        
        .blog-text {
            margin-bottom: 30px;
        }
    }
    @include md {
        margin-bottom: 40px;
        .blog-title {
            margin-bottom: 15px;
        }
    }
    @include sm {
        .blog-title {
            font-size: 24px;
            line-height: 1.3;
        }
        .blog-text {
            margin-bottom: 22px;
        }
        .blog-content {
            padding: 30px;
            margin: -25px 0px 0 20px;
        }
        .blog-meta {
            margin-bottom: 14px;
        }
    }
    @include xs {
        .blog-thumb {
            border-radius: 5px 5px 0 0;
            img {
                border-radius: 5px 5px 0 0;
            }
        }
        .blog-content {
            margin: 0;
            border-radius: 0 0 5px 5px;
        }
    }
}
.blog-author {
    border-radius: 15px;
    padding: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: var(--blog-space-y, 80px);
    .auhtor-img {
        border-radius: 15px;
        overflow: hidden;
    }
    .author-name {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: -0.1em;
        a {
            color: $title-color;
        }
    }
    .author-desig {
        font-size: 14px;
        font-weight: 500;
        color: $theme-color;
        text-transform: uppercase;
        font-family: $title-font;
    }
    .author-text {
        margin-top: 9px;
        margin-bottom: -0.3em;
    }
}
.share-links-title {
    font-size: 18px;
    color: $title-color;
    font-family: $title-font;
    font-weight: 500;
    margin: 0 20px 0 0;
}

.share-links {
    border-bottom: 1px solid $border-color;
    padding: 13px 0 40px;

    > .row {
        align-items: center;
        --bs-gutter-y: 20px;
    }
    .wp-block-tag-cloud,
    .tagcloud {
        gap: 20px;
    }
}
@include ml {    
    .share-links {
        --blog-space-x: 20px;
    }
}

@include lg {
    blockquote, .wp-block-quote {
        padding: 150px 40px 40px 40px;
        &:before {
            left: 40px;
            top: 40px;
        }
    }
}


@include sm {
    blockquote,
    .wp-block-quote {
        cite {
            font-size: 18px;
        }
    }
}

@include xs {
    .share-links-title {
        display: block;
        margin-bottom: 10px;
    }
    .blog-author {
        display: block;
        .auhtor-img {
            margin-bottom: 20px;
            display: inline-block;
        }
    }
    blockquote p, .wp-block-quote p {
        font-size: 18px;
        line-height: inherit; 
    }
    blockquote, .wp-block-quote {
        padding: 140px 30px 30px 30px;
        &:before {
            left: 30px;
            top: 30px;
        }
    }
}
@include vxs {
    .share-links .wp-block-tag-cloud, .share-links .tagcloud {
        gap: 10px;
    }
}

/*blog-details-card**************/
.blog-details-card {
    .blog-thumb {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        .blog-date {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 20px;
            font-weight: 700;
            font-family: $title-font;
            color: $white-color;
            background: $title-color;
            padding: 12px 20px;
            border-radius: 0 5px 0 5px;
        }
        .blog-meta {
            background: $theme-color;
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 0 5px 0 5px;
            padding: 5.5px 20px;
            a {
                color: $white-color;
                font-size: 14px;
                font-weight: 400;
                font-family: $title-font;
                text-transform: capitalize;
                i {
                    color: $white-color;
                }
            }
        }
    }
    .blog-content {
        margin-top: 26px;
    }
    .blog-title {
        font-weight: 900;
    }
    p {
        font-size: 18px;
        font-family: $title-font;
    }
    .blog-details-single-card {
        padding: 40px;
        border-radius: 5px;
    }
}