/*----------------------------------------------
    # why choose us area style here
----------------------------------------------*/
.wcu-thumb-1 {
    img {
        border-radius: 242px;
    }
}
.wcu-card-wrap.right-wrap {
    text-align: flex-end;
    .wcu-card {
        flex-direction: row-reverse;
    }
}
.wcu-card {
    display: inline-flex;
    gap: 22px;
    &:not(:last-child) {
        margin-bottom: 95px;
    }
    .icon {
        height: 65px;
        width: 65px;
        line-height: 65px;
        flex: none;
        text-align: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: $theme-color;
            opacity: 0.1;
            border-radius: 5px;
        }
        img {
            transition: 0.4s;
        }
    }
    .wcu-card-title {
        font-size: 26px;
        font-weight: 900;
        margin-bottom: 8px;
        margin-top: -0.2em;
    }
    .wcu-card-text {
        margin-bottom: -0.5em;
    }
    &:hover {
        .icon {
            img {
                transform: rotateY(180deg);
            }
        }
    }
}
@include ml {
    .wcu-card:not(:last-child) {
        margin-bottom: 55px;
    }
}
@include lg {
    .wcu-thumb-1 {
        text-align: center;
    }
    .wcu-card-wrap.right-wrap {
        text-align: left;
    }
    .wcu-card-wrap.right-wrap .wcu-card {
        flex-direction: row;
    }
}
@include sm {
    .wcu-card:not(:last-child) {
        margin-bottom: 40px;
    }
}
@media (max-width: 320px) {
    .wcu-card {
        flex-wrap: wrap;
    }
}

/*skill-feature 1***********************/
.skill-feature {
    &:not(:last-child) {
        margin-bottom: 25px;
    }
    .skill-feature_title {
        font-size: 26px;
        font-weight: 900;
        color: $title-color;
        margin-bottom: 6px;
    }
    .progress {
        background: #EFEFEF;
        height: 9px;
        position: relative;
        overflow: visible;
        .progress-bar {
            background: $theme-color;
            border-radius: 10px;
        }
        .progress-value {
            position: absolute;
            top: -35px;
            right: 0;
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
            color: $title-color;
        }
    }
}

/************** wcu-thumb-wrap ***************/
.wcu-thumb-wrap {
	position: relative;
	display: flex;
    justify-content: flex-end;
	img {
		border-radius: 10px;
	}
	.img-1 {
		height: fit-content;
	}
	.img-2 {
		margin: 120px 0 0 30px;
	}
    @include lg {
        justify-content: start;
    }
    @include sm {
        display: block;
        img {
            width: -webkit-fill-available;
            max-height: 300px;
            object-fit: cover;
        }
        .img-1 {
            margin-right: 60px;
        }
        .img-2 {
            margin: 30px 0 0 60px;
        }
    }
}