/* Faq 1 ---------------------------------- */ 
.accordion-card {
    margin-bottom: 30px;
    transition: 0.4s ease-in-out;
    overflow: hidden;
    background: $smoke-color;
    border: 0;
    border-radius: 5px;
    .accordion-button {
        font-size: 26px;
        font-weight: 900;
        font-family: $title-font;
        color: $title-color;
        border: 0;
        background-color: transparent;
        padding: 12px 65px 12px 30px;
        min-height: 86px;
        gap: 10px;
        margin-bottom: 0;
        text-align: left;
        transition: 0.3s;
        position: relative;
        box-shadow: none;
        &:after {
            content: "\f067";
            height: 40px;
            width: 40px;
            border-radius: 7px;
            line-height: 38px;
            background: transparent;
            border: 0;
            font-family: $icon-font;
            color: $theme-color;
            font-weight: 900;
            font-size: 18px;
            display: grid;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 22px;
            right: 17px;
            transition: 0.3s ease-in-out;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: $title-color;
            &:after {
                content: '\f068';
                color: $title-color;
                transform: rotate(180deg);
            }
        }
    }
    .accordion-collapse {
        border: none;
    }
    .accordion-body {
        border-radius: 0;
        background-color: transparent;
        border: none;
        padding: 0px 30px 30px 30px;
        margin-top: -0.5em;
        p {
            margin-bottom: -0.6em;
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}
@include ml {
    .accordion-card .accordion-button {
        font-size: 20px;
        min-height: 65px;
    }
    .accordion-card .accordion-button:after {
        font-size: 16px;
        top: 13px;
    }
}
@include xs {
    .accordion-card .accordion-button {
        padding: 15px 65px 15px 30px;
    }
}

/* Faq 2 ---------------------------------- */ 
.accordion-card.style2 {
    background: transparent;
    .accordion-button {
        background: $theme-color;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 700;
        min-height: 60px;
        color: $white-color;
        &:after {
            top: 10px;
            content: "\f078";
            font-size: 16px;
            color: $white-color;
        }
    }
    .accordion-body {
        padding: 30px 30px 10px;
    }
    &:has(.accordion-button.collapsed) {
        .accordion-button {
            background: $smoke-color;
            color: $title-color;
            &:after {
                color: $theme-color;
            }
        }
    }
}

.faq-thumb2 {
    position: relative;
    display: inline-block;
    padding-right: 42px;
    padding-left: 30px;
    padding-bottom: 30px;
    img {
        border-radius: 5px;
    }
    &:after {
        content: '';
        position: absolute;
        width: 190px;
        height: 220px;
        border-bottom: solid 40px $theme-color;
        border-left: solid 40px $theme-color;
        border-right: solid 40px transparent;
        border-top: solid 40px transparent;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    .about-counter-grid {
        border: 2px solid $theme-color;
        position: absolute;
        right: 0;
        bottom: 85px;
        border-radius: 5px;
        .about-counter {
            font-size: 50px;
            font-weight: 900;
            font-family: $title-font;
            margin-bottom: 0;
            margin-top: -0.25em;
        }
        .about-counter-text {
            font-size: 18px;
            font-weight: 400;
            font-family: $title-font;
            margin-bottom: -0.3em;
        }
    }
}
@include xs {
    .faq-thumb2 .about-counter-grid .about-counter {
        font-size: 40px;
    }
    .faq-thumb2 {
        padding-left: 15px;
        padding-bottom: 15px;
        padding-right: 25px;
    }
}
@include vxs {
    .faq-thumb2 .about-counter-grid {
        position: initial;
        animation: none;
        margin-bottom: 20px;
    }
}

/* Faq 3 ---------------------------------- */ 
.faq-thumb3 {
    position: relative;
    display: inline-block;
    padding-right: 42px;
    padding-left: 30px;
    padding-bottom: 30px;
    .faq-shape {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .about-counter-wrap {
        background: $title-color;
        border-left: 5px solid $theme-color;
        border-radius: 5px;
        padding: 30px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0px;
        text-align: center;
        .about-counter {
            font-size: 50px;
            font-weight: 900;
            color: $white-color;
            margin-bottom: -3px;
            margin-top: 11px;
        }
        .about-counter-text {
            color: $white-color;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: -0.3em;
        }
    }
}

/* Faq 3 ---------------------------------- */ 
.faq-wrap {
    border-radius: 20px;
}
.accordion-card.style3 {
    background: $white-color;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .accordion-button {
        background: $theme-color;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 700;
        min-height: 60px;
        color: $white-color;
        &:after {
            top: 10px;
            content: "\f078";
            font-size: 16px;
            color: $white-color;
        }
    }
    .accordion-body {
        padding: 30px 20px 30px;
    }
    &:has(.accordion-button.collapsed) {
        .accordion-button {
            background: $white-color;
            color: $title-color;
            &:after {
                color: $theme-color;
            }
        }
    }
}