.woocommerce-cart-form {
  text-align: center;
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;

  thead {
    background-color: #ecf0f1;

    th {
      border: none !important;
    }
  }

  td:before,
  th {
    font-family: $title-font;
    color: $title-color;
    font-weight: 800;
    border: none;
    padding: 27px 15px;
  }

  td:before {
    content: attr(data-title);
    position: absolute;
    left: 15px;
    top: 50%;
    vertical-align: top;
    padding: 0;
    transform: translateY(-50%);
    display: none;
  }

  td {
    border: none;
    border-bottom: 1px solid #f3f3f3;
    color: #8b8b8b;
    padding: 20px 10px;
    position: relative;
    vertical-align: middle;
  }

  .product-quantity {
    color: $title-color;

    input {
      position: relative;
      top: -2px;
    }

  }


  .cart-productname {
    font-weight: 400;
    font-family: $body-font;
    color: $body-color;
  }

  .cart-productimage {
    display: inline-block;
    border: 2px solid $smoke-color;
  }

  .remove {
    color: $theme-color;
    font-size: 18px;
  }

  .quantity {
    display: inline-flex;
    align-items: center;
  }

  .qty-btn {
    border: 2px solid $smoke-color !important;
    background-color: transparent;
    color: #b8c6d0;
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 12px;
    border-radius: 4px;

    &:hover {
      background-color: $theme-color;
      color: $white-color;
      border-color: $theme-color !important;
    }
  }

  .qty-input {
    vertical-align: middle;
    border: 2px solid $smoke-color;
    width: 70px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    color: $title-color;
    font-weight: 700;
    margin: 0 10px;
    border-radius: 4px;
    padding: 0;

    @include inputPlaceholder {
      color: $title-color;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .actions {
    text-align: right;
    vertical-align: middle;

    >.btn {
      font-size: 16px;
      padding: 20px 28px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

}

.cart_totals {
  border: 1px solid #ecf0f1;

  th,
  td {
    vertical-align: top;
    padding: 20px 20px;
    border: none;
    border-bottom: 1px solid #ecf0f1;
    font-size: 14px;
    color: $title-color;
    width: 55%;

    &:first-child {
      width: 45%;
      background-color: #f9fbfb;
      font-weight: 700;
      font-size: 14px;
      color: #333333;
    }
  }

}

@include md {
  .cart_table {

    th {
      padding: 23px 8px;
      font-size: 14px;
    }

    .cart-productname {
      font-size: 14px;
    }


    .actions {
      text-align: center;


    }

  }
}

@include sm {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;

    thead {
      display: none;
    }

    td {
      padding: 15px;
      display: block;
      width: 100%;
      padding-left: 25%;
      text-align: right;
      border: 1px solid #f3f3f3;
      border-bottom: none;

      &::before {
        display: block;
      }

      &:last-child {
        border-bottom: 1px solid #f3f3f3;
      }

      &.actions {
        padding-left: 15px;
        text-align: center;

        >.btn {
          margin-top: 10px;
          margin-right: 0;
          display: block;
          width: max-content;
          margin-left: auto;
          margin-right: auto;

          &:last-child {
            margin-right: auto;
          }
        }
      }
    }
  }

  .cart_totals {

    th,
    td {
      padding: 15px 10px;

      &:first-child {
        width: 17%;
        line-height: 1.4;
      }
    }
  }
}

/*Checkout Area***************/
.shipping-area {
    background: $white-color;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 60px;
}
.checkout-form {
  input[type=radio] ~ label::before {
    position: relative;
    display: inline-block;
    margin-right: 15px;
  }
  input[type=radio] ~ label {
    padding-left: 0;
  }
  .footer-currency {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 40px;
    a {
      border-radius: 5px;
      border: 1px solid $border-color;
      display: block;
      &:hover {
        border-color: $theme-color;
      }
    }
  }
}