.breadcumb-menu {
    max-width: 100%;
    padding: 0;
    list-style-type: none;
    position: relative;
    margin: 35px 0 -0.4em;
    li {
        display: inline-block;
        padding-right: 11px;
        list-style: none;
        position: relative;
        &:after {
            content: "\f105";
            position: relative;
            margin-left: 16px;
            font-size: 14px;
            font-weight: 700;
            font-family: $icon-font;
            color: $white-color;
        }

        &:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
                display: none;
            }
        }
    }

    li,
    a,
    span {
        white-space: normal;
        color: inherit;
        word-break: break-word;
        font-weight: 400;
        font-size: 18px;
        font-family: $title-font;
        color: $white-color;
    }
    a:hover {
        color: $theme-color;
    }
}

.breadcumb-title {
    color: $white-color;
    margin: -0.15em 0 -0.2em 0;
	line-height: 1.1;
    font-size: 38px;
    font-weight: 700;
    font-family: $title-font;
    z-index: 1;
    position: relative;
}

.breadcumb-wrapper {
    background: $title-color;
    padding: 297px 0 155px;
    overflow: hidden;
    position: relative;
    z-index: auto;   
    .breadcumb-thumb {
        position: absolute;
        bottom: 0;
    }
}
@include lg {
    .breadcumb-wrapper {
        padding: 262px 0 120px;
    }
}
@include md {
    .breadcumb-wrapper {
        text-align: center;
        padding: 242px 0 100px;
    }
}
@include xs {
    .breadcumb-title {
        font-size: 40px;
    }
    .breadcumb-menu {
        text-align: center;
        margin: 22px 0 -0.45em 0;
    }
    .breadcumb-menu li, .breadcumb-menu a, .breadcumb-menu span {
        font-size: 14px;
    }
}