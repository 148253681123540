/*----------------------------------------------
    # Appointment Area 1
----------------------------------------------*/
.appointment-area-1 {
    position: relative;
    z-index: 1;
    .appointment-thumb-1 {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -1;
        max-width: 70%;
        img {
            border-radius: 5px 0 0 5px;
            height: 100%;
            object-fit: cover;
        }
    }
}
.appointment-form-wrap {
    padding: 60px;
    border-radius: 5px;
    margin: 80px 0;
    @include xs {
        padding: 50px 30px;
    }
}
/* Appointment 2 ---------------------------------- */
.appointment-area-2 {
    position: relative;
    background-size: cover;
    .appointment-form-wrap {
        margin: 0;
    }
    .appointment-thumb-2 {
        position: absolute;
        bottom: 0;
    }
}
@include vxs {
    .appointment-area-2 .appointment-form-wrap {
        .form-group {
            margin-bottom: 15px;
        }
    }
}