/* Pricing Card ---------------------------------- */
.pricing-shape-img1 {
    opacity: 0.2;
    left: 53px;
    top: 288px;
    .about1-shape-img-2 {
        margin-left: -35px;
        margin-bottom: -120px;
    }
    @include xxl {
        left: 42px;
    }
}
.pricing-card {
    border-radius: 5px;
    background: $white-color;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
    padding: 60px;
    position: relative;
    z-index: 0;
    display: flex;
    gap: 80px;
    &_icon {
        display: inline-block;
        height: 80px;
        width: 80px;
        line-height: 80px;
        text-align: center;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        background: $theme-color;
        img {
            transition: 0.4s;
        }
    }
    &_price {
        font-size: 50px;
        font-weight: 900;
        font-family: $title-font;
        color: $title-color;
        transition: 0.4s;
        margin-bottom: 34px;
        margin-top: -0.25em;
        .duration {
            display: block;
            font-size: 18px;
            font-weight: 400;
        }
    }
    &_title {
        font-size: 26px;
        font-weight: 900;
        font-family: $title-font;
        margin-bottom: 23px;
        transition: 0.4s;
        margin-top: -0.25em;
    }
    .checklist.style2 {
        margin-bottom: 42px;
        li {
            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }
    }
    &:hover {
        .pricing-card_icon {
            img {
                transform: rotateY(180deg);
            }
        }
    }
}
@include lg {
    .pricing-card {
        padding: 50px;
        gap: 30px;
    }
}
@include md {
    .pricing-card {
        gap: 60px;
        justify-content: center;
    }
}
@include xs {
    .pricing-card {
        gap: 25px;
        padding: 40px;
        flex-wrap: wrap;
        justify-content: start;
        .pricing-card-price-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            flex-direction: column-reverse;
            .pricing-card_price {
                width: 100%;
                margin-bottom: 0;
                .duration {
                    display: inline-block;
                }
            }
        }
    }
}
@media (max-width: 320px) {
    .pricing-card {
        padding: 30px 30px;
    }
}

/* Pricing Card 2 ---------------------------------- */
.pricing-card.style2 {
    display: block;
    padding: 0;
    background: $smoke-color;
    box-shadow: none;
    transition: 0.4s;
    .pricing-card-icon-wrap {
        display: flex;
        padding: 45px 0px 25px 35px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            width: calc(100% - 20px);
            left: 10px;
            background: $border-color;
        }
        .pricing-card_icon {
            background: transparent;
            border-radius: 0;
            width: auto;
            height: auto;
            line-height: normal;
            flex: none;
            img {
                transform: none;
            }
        }
    }
    .pricing-card_title {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 700;
        background: $theme-color;
        border-radius: 5px 0 0 5px;
        padding: 17px 20px;
        min-width: 220px;
        color: $white-color;
        text-align: center;
    }
    .pricing-card-details {
        padding: 35px 40px 45px;
    }
    .pricing-card_price {
        font-size: 26px;
        font-weight: 900;
        margin-bottom: 3px;
    }
    .pricing-card_currency {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 28px;
    }
    .checklist.style2 {
        margin-bottom: 52px;
    }
    .btn {
        width: 100%;
        background: $white-color;
        color: $title-color;
        border: 2px solid transparent;
        &:hover {
            border-color: $theme-color;
        }
    }
    &.pricing-card-active,
    &:hover {
        background: #FBD7DD;
    }
} 
@include lg {
    .pricing-card.style2 .pricing-card-icon-wrap {
        gap: 40px;
    }
    .pricing-card.style2 .pricing-card_title {
        margin-left: auto;
        min-width: auto;
        padding: 13px 20px;
        font-size: 18px;
    }
}